.slides {
  margin-top: -7px;
  z-index: 0;
  position: relative;
}

.text_lines {
  position: absolute;
  font-weight: 900;
  font-size: 80px;
  line-height: 100px;
  text-align: center;
  top: 10%;
  color: #e9dcdc;
  padding: 40px;
}

.Slide_btn {
  position: absolute;
  width: 156px;
  height: 55.62px;
  background-color: #ffce00;
  border-radius: 8px;
  border-style: none;
  top: 73%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 20px;
}

@media only screen and (max-width: 450px) {
  .text_lines{
    padding: 20px;
    font-size: 30px;
    line-height: 30px;
    top: 7.3%;
  }
  .Slide_btn{
    width: 80px;
    height: 30px;
    font-size: 10px;
  }
}
