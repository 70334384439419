.trip {
  padding: 0px 120px;
  margin-top: 100px;

}

.trip_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.trip_cards > div {
  padding: 40px 0px;
}

.trip_p{
  margin-bottom: 0px;
  margin-top: 80px;
}

@media only screen and (max-width: 450px) {
  .trip {
    padding: 0px 20px;
    margin-top: 50px;
  }
  .trip_cards > div {
    padding: 20px 0px;
  }
  .trip_p{
    margin-top: 50px;
  }
}
