.navbar {
  display: flex;
  align-items: center;
  background: #ffce00;
  justify-content: space-around;
  padding: 10px 30px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.navbar__title {
  margin-right: auto;
}

.container__item {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}

.logo {
  width: 200px;
  height: 70px;
}

.strip{
  margin-top:-15px; 
  z-index:10
}

.menu_container{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.navbar-menu{
  margin-left: 1rem;

  display: none;
  position: relative;
  z-index: 20;
}

.navbar-menu_container{
  margin-top: -1px;
  background: #ffce00;
  padding: 2px;
  margin-bottom: 5px;

}

@media only screen and (max-width: 450px) {
  .container {
    display: none;
  }
  .container__item{
    font-size: 14px;
  }
  .logo{
    width: 120px;
    height: 40px;
  }
  .navbar{
    padding: 3px 10px;
  }
  .strip{
    margin-top: -5px;
    height: 10px;
  }
  .navbar-menu{
    display: flex;
  }
}
