.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  margin: 0 5px;
}

.card_text {
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  margin-top: 20px;
  max-width: 450px;
  text-align: center;
}

.card>img {
  width: max-content;
}

@media only screen and (max-width: 450px) {
  .card>img {
    width: 100%;
  }

  .card_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .card {
    justify-content: center;
    flex-direction: column;
    height: inherit;
    margin: 0px;

  }
}