.feature_card{
    width: 300px;
    height: 350px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.feature_img{
    width: 90px;
    height: 90px;
}

.feature_content{
    text-align: center;
    margin-top: 20px;
}

.feature_heading{
    font-weight: 600;
    font-size: 22px;
    margin: 10px;
}

.feature_card:hover{
    box-shadow: 5px 10px 18px #888888
}

.feature_text{
    padding: 5px;
}