footer {
  height: 400px;
  background-color: black;
  display: flex;
  justify-content: space-around;
}

div > p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgb(0, 0, 0);
}

p>a{
  color: white;
}

.image {
  display: flex;
  flex: 0.3;
  padding: 60px;
}

.tags {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 0.3;
  padding: 60px;
}

.contact {
  display: flex;
  flex: 0.3;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
}

.footer_logo {
  width: 200px;
  height: 66.31px;
}

.contact > p {
  margin: 15px 0px;
  color: white;
}

@media only screen and (max-width: 450px) {
  footer {
    flex-direction: column;
    height: 280px;
  }

  .image,
  .tags,
  .contact {
    padding: 10px;
  }
  .contact > p {
    margin: 4px 0px;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tags {
    flex-direction: row;
  }
  div > p {
    font-size: 12px;
    line-height: 17px;
  }
  .footer_logo {
    width: 150px;
    height: 40px;
  }
  .contact{
    align-items: center;
    padding: 0px 40px;
    text-align: center;
  }
  div>p:first-child{
    padding-top: 9px;
  }

}
