.about_us {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.article_1 {
  font-size: 24px;
  line-height: 41.52px;
  text-align: center;
  margin-bottom: 40px;
}

.article_2 {
  font-size: 25px;
  line-height: 60px;
  padding: 20px 64px;
}

.sub_section {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.features {
  display: flex;
  flex: 0.5;
  justify-content: space-around;
  flex-direction: column;
}

.features_items {
  display: flex;
  flex-direction: row;
  padding: 40px;
}

.features_items_num {
  color: #ffce00;
  font-weight: 700;
  font-size: 90px;
  line-height: 66px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 10px;
  width: 115px;
  height: 100px;
}

.features_items_text {
  font-size: 20px;
  max-width: 300px;
  line-height: 30px;
}

.text_highight {
  font-weight: 700;
}

.request {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
}

.car_image{
  max-width: 582px;
  margin: auto;
}

.features_headline{
  margin-top: 30px;
}

.features_container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

@media only screen and (max-width: 450px) {
  .sub_section {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .car_image{
    max-width: 80%;
    margin-bottom: 10px;
  }
  .about_us{
    padding: 6px;
    margin-top: 10px;
  }
  .article_1{
    font-size: 14px;
    line-height: 21.52px;
    margin-bottom: 16px;
  }
  .article_2 {
    font-size: 15px;
    line-height: 30px;
    padding: 10px 6px;
  }
  .features_items {
    padding: 10px;
  }
  .features_items_num {
    color: #ffce00;
    font-weight: 700;
    font-size: 45px;
    line-height: 30px;
    margin: 5px;
    width: 55px;
    height: auto;
    
  }
  .features_items_text {
    font-size: 10px;
    max-width: 300px;
    line-height: 20px;
  } 
  .request {
    display: block;
    width: 100%;
    margin-top: 30px;
  }
  .gap{
    margin-top: 30px;
  }

  .features_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
}
