.login_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 26px;
  width: 80%;
  gap: 10px;
  background: #fdf9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: auto;
}

.inputBox {
  display: block;
  border-radius: 8px;
  border-style: none;
  background: #f5f5f5;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 80%;
  height: 64px;
  outline: none;
  padding: 0px 20px;
  margin: 20px 0px;
}

.inputBox:focus {
  border: 0.5px solid rgb(198, 201, 201);
}

.btn {
  margin-top: 20px;
  padding: 10px 25px;
  cursor: pointer;
  background: #0f0f0e;
  color: white;
  border-style: none;
  width: 169.2px;
  height: 41px;
}

.headline_text {
  font-weight: 700;
  font-size: 30px;
  line-height: 115px;
}

@media only screen and (max-width: 450px) {
  .login_box {
    padding: 10px;
    gap: 5px;
  }
  .inputBox {
    font-size: 12px;
    line-height: 14px;
    width: 90%;
    height: 40px;
    padding: 0px 10px;
    margin: 10px 0px;
  }
  .headline_text {
    font-weight: 700;
    font-size: 20px;
    line-height: 44px;
  }
  .btn {
    margin-top: 6px;
    padding: 5px 12px;
    width: 120px;
    height: 30px;
  }
}
