.seating {
  padding: 120px;
}

.headline {
  font-weight: 700;
  font-size: 25px;
  line-height: 66px;
  margin-bottom: -20px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.cards > div {
  padding: 40px 0px;
}

p:first-child {
  margin-top: -40px;
}

@media only screen and (max-width: 450px) {
  .seating {
    padding: 20px;
    margin-top: 35px;
  }
  .headline {
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 5px;
  }
  p:first-child {
    margin-top: 0px;
  }
  .cards {
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
  }
  .cards > div {
    padding: 20px 0px;
  }
}
