.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 571.12px;
  width: 100%;
}

@media only screen and (max-width: 450px) {
  .slide{
    height: 230px;
  }
}
