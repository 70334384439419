
.table {
  width: 90%;
  margin: auto;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 30px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
}

table {
  table-layout: fixed;
  width: 100%;
}

.element{
    border-style: none;
}

.element>td{
    border-style: none;
  
}

.terms{
  margin: 30px 25%;
}

.terms_heading{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
  .table{
    font-size: 10px;
  }
  .terms{
    margin: 30px 5%;
  }
}