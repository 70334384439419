@import url(https://fonts.googleapis.com/css?family=Poppins);

.App{
  font-family: "Poppins";
}
a{
  text-decoration: none;
  color: black;
}
.title {
  font-weight: 700;
  font-size: 50px;
  line-height: 115px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 450px) {
  .title{
    font-size: 22px;
    line-height: 30px;
  }
}
