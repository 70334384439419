.header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_items{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
}

.header_items>div{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icons{
    width: 25px;
    cursor: pointer;
}

.txt{
    line-height: 0px;
    color: black;
}

@media only screen and (max-width: 450px) {
    .header_items{
        width: 50%;
        padding: 3px;
    }
    .icons{
        width: 18px;
    }
    .txt{
        display: none;
    }
}
