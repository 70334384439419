.gallery{
  margin-top: 20px;
  margin-bottom: 60px;
}

.grid-container {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  justify-content: center;
}

.grid-container > img {
  flex-basis: 20%;
  width: 300px;
  padding: 5px;
  margin: 5px;
}

@media only screen and (max-width: 450px) {
  .grid-container > img {
    flex-basis: 100%;
    width: 80%;
  }
  .gallery{
    margin-top: 15px;
    margin-bottom: 40px;
  }
}
